<template>
	<Clients />
</template>
<script>
import Clients from '@/modules/Clients/index.vue'
export default {
	components: {
		Clients
	}
}
</script>
