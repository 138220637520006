<template>
	<BasePageLayout>
		<div slot="page__title">Danh sách khách hàng</div>
		<div class="pa-8">
			<FilterClients
				@create="openCreateDialog"
				@search="data => onSearch(data)"
			/>
			<TableClients :items="clients" :search="search" />
		</div>
		<CreateClientDialog ref="create-dialog" />
	</BasePageLayout>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateClientDialog from '@/modules/Clients/components/CreateClientDialog'
import TableClients from '@/modules/Clients/components/TableClients'
import FilterClients from '@/modules/Clients/components/FilterClients'
import BasePageLayout from '@/templates/layout/BasePageLayout'
export default {
	components: {
		BasePageLayout,
		FilterClients,
		TableClients,
		CreateClientDialog
	},
	async mounted() {
		this.$message.loading('Đang lấy dữ liệu ...', 20000)
		await this.fetchVendors({
			vendorId: 'main-page'
		})
		await this.fetchClients({dataType: 'user-profile', role: 'admin'})
		this.$message.close()
	},
	data() {
		return {
			search: ''
		}
	},
	computed: {
		...mapGetters({
			vendors: 'vendor/getVendors',
			clients: 'client/getClients'
		})
	},
	methods: {
		...mapActions({
			fetchVendors: 'vendor/fetchVendors',
			fetchClients: 'client/fetchClients'
		}),
		onSearch(data) {
			this.search = data
			this.$message.loading('123...', 20000)
		},
		openCreateDialog() {
			this.$refs['create-dialog'].openDialog()
		}
	}
}
</script>
