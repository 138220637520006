var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"has-border",attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.listClients,"search":_vm.search},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updatedAt).format('DD/MM/YYYY'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":("" + (item.status === 'verified' ? 'green' : 'gray'))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-check")])],1)]}}],null,true)},[_c('span',{style:(("color: " + (item.status === 'verified' ? 'green' : 'gray') + ";"))},[_vm._v(_vm._s(item.status === 'verified' ? 'Đã kích hoạt' : 'Chưa kích hoạt'))])])]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"cursor-pointer primary--text",attrs:{"target":"_blank","href":("https://" + (item.domain))}},[_vm._v(_vm._s(item.domain))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.$router.push(("/client-detail/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }