<template>
	<div class="d-flex justify-space-between">
		<div class="d-inline-flex">
			<BaseInput
				v-model="search"
				label="Lọc theo tên khách hàng"
				placeholder="Nhập tên khách hàng cần lọc"
				style="width: 360px"
			/>
			<BaseButton
				class="ml-3"
				icon="mdi-magnify"
				text="Lọc và tìm"
				color="primary"
				@click="$emit('search', search)"
				className="text-none"
			/>
		</div>
		<BaseButton
			@click="$emit('create')"
			icon="mdi-account-plus"
			text="Tạo khách hàng mới"
		/>
	</div>
</template>
<script>
export default {
	data() {
		return {
			search: ''
		}
	}
}
</script>
