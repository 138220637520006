<template>
	<v-data-table
		class="has-border"
		disable-sort
		:headers="headers"
		:items="listClients"
		:search="search"
	>
		<template v-slot:[`item.updatedAt`]="{item}">
			{{ moment(item.updatedAt).format('DD/MM/YYYY') }}
		</template>
		<template v-slot:[`item.status`]="{item}">
			<v-tooltip top>
				<template v-slot:activator="{on, attrs}">
					<v-btn
						v-bind="attrs"
						v-on="on"
						icon
						:color="`${item.status === 'verified' ? 'green' : 'gray'}`"
					>
						<v-icon small>mdi-account-check</v-icon>
					</v-btn>
				</template>
				<span
					:style="`color: ${item.status === 'verified' ? 'green' : 'gray'};`"
					>{{
						item.status === 'verified' ? 'Đã kích hoạt' : 'Chưa kích hoạt'
					}}</span
				>
			</v-tooltip>
		</template>
		<template v-slot:[`item.domain`]="{item}">
			<a
				class="cursor-pointer primary--text"
				target="_blank"
				:href="`https://${item.domain}`"
				>{{ item.domain }}</a
			>
		</template>
		<template v-slot:[`item.actions`]="{item}">
			<div class="d-flex justify-end">
				<v-btn
					icon
					color="blue"
					@click="$router.push(`/client-detail/${item.id}`)"
				>
					<v-icon small>mdi-pencil-outline</v-icon>
				</v-btn>
			</div>
		</template>
	</v-data-table>
</template>
<script>
import moment from 'moment'
import get from 'lodash-es/get'
export default {
	props: {
		items: Array,
		search: String
	},
	computed: {
		listClients() {
			if (!this.items) return []
			return this.items.map(item => {
				return {
					id: item.id,
					clientName: item.name,
					domain: get(item, 'vendorInfo.learn.hostname', ''),
					status: item.state,
					updatedAt: item.updatedAt
				}
			})
		}
	},
	data() {
		return {
			moment,
			headers: [
				{text: 'Tên khách hàng', value: 'clientName', width: '20%'},
				{text: 'Địa chỉ trang web', value: 'domain', width: '15%'},
				{text: 'Trạng thái', value: 'status', width: '5%', align: 'center'},
				{text: 'Cập nhật', value: 'updatedAt', width: '5%', align: 'center'},
				{text: 'Thao tác', value: 'actions', width: '5%', align: 'end'}
			]
		}
	}
}
</script>
